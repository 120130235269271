import React, { FC, useState } from 'react'
import Button from '../Button'
import './ContactForm.scss'

const ContactForm: FC = () => {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [messageSent, setMessageSent] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault()
    if (!isFormValid()) {
      return
    }
    const url = 'https://getform.io/f/1bed7bee-5fed-4ea5-b8f2-8763a90ec36d'
    const formData = new FormData()
    formData.append('email', email)
    formData.append('message', message)

    fetch(url, { method: 'POST', body: formData })
      .then(() => setMessageSent(true))
      .catch((error) => console.log(error))
  }

  const isFormValid = (): boolean => {
    const emailRegex = /\S+@\S+\.\S+/

    return !!email && !!message && emailRegex.test(email)
  }

  if (messageSent) {
    return (
      <>
        <h3>Message sent!</h3>
        <p>Thank you for contacting us.</p>
      </>
    )
  } else {
    return (
      <>
        <h3>
          <span>Shoot us a message</span>
          <br />
          Let&apos;s create <br />
          magic together
        </h3>
        <form onSubmit={handleSubmit} className="contact-form">
          <input
            type="text"
            placeholder="Your email..."
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <textarea
            placeholder="Message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <Button>Send</Button>
        </form>
      </>
    )
  }
}

export default ContactForm
