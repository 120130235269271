import React, { FC } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import TextWithImage from '../TextWithimage'
import ContactForm from '../ContactForm'
import FadeInSection from '../FadeInSection'
import './Contact.scss'

const Contact: FC = () => {
  return (
    <div className="mountain-and-stars-bg">
      <FadeInSection>
        <TextWithImage
          text={<ContactForm />}
          image={
            <StaticImage
              src="../../assets/images/contact-us.png"
              alt="Contact us"
            />
          }
          imageFirst={true}
        />
      </FadeInSection>
    </div>
  )
}

export default Contact
