import React, { FC } from 'react'
import Anchor from '../components/Anchor'
import Layout from '../components/Layout'
import Home from '../components/Home'
import AboutUs from '../components/AboutUs'
import OurWork from '../components/OurWork'
import Contact from '../components/Contact'
import Technologies from '../components/Technologies'
import FullService from '../components/FullService'

const IndexPage: FC = () => {
  return (
    <Layout title="Home">
      <Home />
      <Anchor id="about-us" />
      <AboutUs />
      <Anchor id="our-work" />
      <OurWork />
      <Anchor id="full-service" />
      <FullService />
      <Anchor id="contact" />
      <Contact />
      <Anchor id="technologies" />
      <Technologies />
    </Layout>
  )
}

export default IndexPage
