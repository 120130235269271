import React, { FC } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import TextWithImage from '../TextWithimage'
import FadeInSection from '../FadeInSection'

const AboutUs: FC = () => {
  return (
    <FadeInSection>
      <TextWithImage
        text={
          <>
            <h2>About Us</h2>
            <h3>
              Expert software <br />
              development
              <br />
              <span>and consultancy</span>
            </h3>
            <p>
              We have years worth of experience in solving complex problems
              across different industries, including sports betting, web
              analytics, advertising, and sales, as well as entertainment and
              music industry, blockchain, AI integrations, and many more.
            </p>
          </>
        }
        image={
          <StaticImage
            src="../../assets/images/about-us.png"
            alt="About us"
            placeholder="blurred"
          />
        }
      />
    </FadeInSection>
  )
}

export default AboutUs
