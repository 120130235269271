import React, { FC } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import ScrollButton from '../ScrollButton/ScrollButton'
import './Home.scss'

const Home: FC = () => {
  return (
    <div className="home-page">
      <div className="content">
        <div className="home-page-content">
          <StaticImage
            src="../../assets/images/NeonCat-logo.png"
            alt="Neon Cat"
            placeholder="none"
          />
          <h1>Glowing solutions for your ideas</h1>
        </div>
      </div>
      <Link to="#about-us" aria-label="About Us">
        <ScrollButton />
      </Link>
    </div>
  )
}

export default Home
