import React, { FC } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import FadeInSection from '../FadeInSection'
import './Technologies.scss'

const path = '../../assets/images/technologies'

const Technologies: FC = () => {
  return (
    <FadeInSection>
      <div className="content">
        <div className="section technologies">
          <h2>We use modern and reliable technologies</h2>
          <StaticImage
            src={`${path}/javascript.png`}
            title="JavaScript"
            alt="JavaScript"
          />
          <StaticImage
            src={`${path}/typescript.png`}
            title="TypeScript"
            alt="TypeScript"
          />
          <StaticImage src={`${path}/react.png`} title="React" alt="React" />
          <StaticImage
            src={`${path}/angular.png`}
            title="Angular"
            alt="Angular"
          />
          <StaticImage src={`${path}/node.png`} title="Node.js" alt="Node.js" />
          <StaticImage src={`${path}/nest.png`} title="NestJS" alt="NestJS" />
          <StaticImage src={`${path}/java.png`} title="Java" alt="Java" />
          <StaticImage src={`${path}/spring.svg`} title="Spring" alt="Spring" />
          <StaticImage src={`${path}/python.png`} title="Python" alt="Python" />
          <StaticImage src={`${path}/flask.png`} title="Flask" alt="Flask" />
          <StaticImage src={`${path}/django.png`} title="Django" alt="Django" />
          <StaticImage src={`${path}/elixir.png`} title="Elixir" alt="Elixir" />
          <StaticImage
            src={`${path}/phoenix.png`}
            title="Phoenix"
            alt="Phoenix"
          />
          <StaticImage
            src={`${path}/postgres.png`}
            title="PostgreSQL"
            alt="PostgreSQL"
          />
          <StaticImage src={`${path}/mysql.png`} title="MySQL" alt="MySQL" />
          <StaticImage
            src={`${path}/mongo.png`}
            title="MongoDB"
            alt="MongoDB"
          />
          <StaticImage src={`${path}/dynamo.png`} title="Dynamo" alt="Dynamo" />
          <StaticImage
            src={`${path}/graphql.png`}
            title="GraphQL"
            alt="GraphQL"
          />
          <StaticImage
            src={`${path}/aws.png`}
            title="Amazon Web Services"
            alt="Amazon Web Services"
          />
          <StaticImage src={`${path}/docker.png`} title="Docker" alt="Docker" />
          <StaticImage src={`${path}/redis.png`} title="Redis" alt="Redis" />
        </div>
      </div>
    </FadeInSection>
  )
}

export default Technologies
