import React, { FC } from 'react'

import './Anchor.scss'

interface Props {
  id: string
}

const Anchor: FC<Props> = ({ id }) => <div className="anchor" id={id} />

export default Anchor
