import React, { FC } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import TextWithImage from '../TextWithimage'
import FadeInSection from '../FadeInSection'

import './OurWork.scss'

const OurWork: FC = () => {
  return (
    <div className="mountain-and-stars-bg">
      <FadeInSection>
        <TextWithImage
          text={
            <div className="section our-work">
              <h2>Our Work</h2>
              <h3>
                Custom web and mobile <br />
                <span>solutions - front to back</span>
              </h3>
              <p>
                Our engineers have built from the ground up and scaled various
                kinds of system
              </p>
              <ul>
                <li>real-time data, serving thousands of users</li>
                <li>microservice architectures</li>
                <li>serverless backends</li>
                <li>various mobile applications</li>
                <li>a custom purpose built content management solutions</li>
              </ul>
            </div>
          }
          image={
            <StaticImage
              src="../../assets/images/our-work.png"
              alt="Our work"
              placeholder="blurred"
            />
          }
          imageFirst={true}
        />
      </FadeInSection>
    </div>
  )
}

export default OurWork
