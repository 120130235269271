import React, { FC } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import FadeInSection from '../FadeInSection'
import './FullService.scss'

const FullService: FC = () => {
  return (
    <FadeInSection>
      <div className="content">
        <div className="section full-service">
          <h2>Full Service</h2>
          <h3>
            From initial idea <span>or mockups</span>
            <br />
            to production<span> at scale</span>
          </h3>
          <div className="full-service-items">
            <div>
              <div>
                <StaticImage
                  src="../../assets/images/full-service-1.png"
                  alt="Full service modern software"
                  width={80}
                />
              </div>
              We are using modern software development methodologies that let us
              plan everything carefully beforehand but also adapt and be
              flexible when appropriate.
            </div>
            <div>
              <div>
                <StaticImage
                  src="../../assets/images/full-service-2.png"
                  alt="Full service infrastructure"
                  width={80}
                />
              </div>
              Necessary infrastructure and different environments are set up
              from day one, so moving from development to production is always a
              business matter rather than a technical challenge.
            </div>
            <div>
              <div>
                <StaticImage
                  src="../../assets/images/full-service-3.png"
                  alt="Full service test coverage"
                  width={80}
                />
              </div>
              We aim for highest possible test coverage which gives everyone a
              peace of mind regarding the system stability.
            </div>
          </div>
        </div>
      </div>
    </FadeInSection>
  )
}

export default FullService
