import React, { FC, ReactNode } from 'react'
import './TextWithImage.scss'

interface Props {
  image: ReactNode
  text: ReactNode
  imageFirst: boolean
}

const TextWithImage: FC<Props> = ({ image, text, imageFirst }) => {
  return (
    <div className="content">
      <div
        className={`section text-with-image${imageFirst ? ' image-first' : ''}`}
      >
        <div className="page-text">{text}</div>
        <div className="page-image">{image}</div>
      </div>
    </div>
  )
}

export default TextWithImage
